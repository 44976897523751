import React from 'react';
import styled from 'styled-components';

import { black, primary, text70 } from 'styles/colors';
import {
  Container,
  H1,
  H3,
  Header,
  Regular,
  Seo,
  Svg,
  TextColor,
} from 'components';
import { mobile, useQuery } from 'styles/breakpoints';

const PrivacyPolicy: React.FC = React.memo(() => {
  const { isMobile } = useQuery();

  return (
    <>
      <Seo title="Privacy policy | Pulsio" />
      <Container>
        <Header />
        <Svg
          src="icons/leaves_2"
          width="52"
          height="36"
          viewBox="0 0 52 36"
          fill="none"
          style={{ display: 'block', margin: '0 auto' }}
        />
        <H1
          margin={isMobile ? '0.625rem 0 3.125rem' : '0.625rem 0 7.625rem'}
          textAlign="center"
        >
          Privacy
          <br />
          Policy
        </H1>
        <Content>
          <H3 margin="0 0 1.875rem">1. Introduction</H3>
          <Regular margin="0 0 1.25rem">
            In this privacy policy we, MAX NUTRITION, LLC company code
            305420438, registered address at Gedimino ave. 1A-13, LT-01103
            Vilnius, Lithuania, office address at Gedimino ave. 1A-13, LT-01103
            Vilnius, Lithuania, e-mail hi@pulsio.me (“Company”, “we”, “us” or
            “our”), explain how we handle your personal data when you visit our
            website, use our apps, contact us through our official social media
            pages or email, and/or use our services.
          </Regular>
          <Regular margin="0 0 1.25rem">
            Please note that in case you purchase physical goods while using our
            services, Max Nutrition UAB, company code 305420438, with registered
            office at Gedimino pr. 1A-13 office address at Gedimino ave. 1A-13,
            LT-01103 Vilnius, Lithuania is responsible for data processing with
            regard to this particular service. In such cases “Company”, “we”,
            “us” or “our” shall refer to Max Nutrition UAB. In case you have any
            privacy related inquiries or requests associated to physical goods,
            you may contact us by email: hi@pulsio.me
          </Regular>
          <Regular margin="0 0 1.25rem">
            We will ask you to consent to our use of cookies in accordance with
            the terms of this notice when you first visit our website.
          </Regular>
          <Regular margin="0 0 1.25rem">
            In this notice you will find the answers to the following questions:
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>1.</b> how we use your data;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>2.</b> when we provide your data to others;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>3.</b> how long we store your data;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>4.</b> what is our marketing policy;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>5.</b> what rights related to personal data you possess;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>6.</b> how we use cookies;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>7.</b> other issues that you should take into account.
          </Regular>
          <Regular margin="0 0 1.25rem">
            In case of any inquiries or if you would like to exercise any of
            your rights provided in this notice, you may submit such inquiries
            and requests by means provided in Contacts section.
          </Regular>
          <Regular margin="0 0 1.25rem">
            You may also contact us regarding all privacy related issues by
            email: hi@pulsio.me
          </Regular>
          <Regular margin="0 0 1.25rem">
            All the definitions used in this privacy policy have the same
            meaning as prescribed in Company’s General Conditions unless
            expressly provided otherwise in this privacy policy. This privacy
            policy forms an integral part of Company’s General Conditions.
          </Regular>
          <Regular>
            In the event this privacy policy is translated into other languages
            and if there are differences between the English version and such
            translation, the English version shall prevail, unless otherwise
            provided.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">
            2. How we use your personal data?
          </H3>
          <Regular margin="0 0 1.25rem">
            <b>2.1</b> This Section provides the following information:
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>1.</b> categories of personal data, that we process;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>2.</b> in case of the personal data that we did not obtain
            directly from you, the source and specific categories of that data;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>3.</b> in case of the personal data that we did not obtain
            directly from you, the source and specific categories of that data;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>4.</b> the legal bases of the processing.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>2.2</b> We process your account data ( “account data” ). The
            account data may include your name and email address, phone number
            and other data that you provide while registering as well as your
            purchase history. We obtain such data directly from you. We process
            account data for the purposes of operating our website, providing
            our services, ensuring the security of our website and services and
            communicating with you. The legal basis for this processing is the
            performance of a contract between you and us and/or taking steps, at
            your request, to enter into such a contract as well as our
            legitimate interest, namely monitoring and improving our website and
            services.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>2.3</b> We process information relating to provision of services
            by us to you ( “service data” ). The service data may include your
            contact details (such as your email address), bank account and
            transaction details as well as other information that you provide to
            us while filling up the relevant questionnaires (such may include
            sensitive personal data, related to your health, in case such data
            is necessary to provide the relevant service). The service data is
            processed to supply goods and provide services as well as keep
            proper records of those transactions. The legal basis for this
            processing is the performance of a contract between you and us
            and/or taking steps, at your request, to enter into such a contract
            and our legitimate interests, namely the proper administration of
            our website and business. In case of sensitive personal data,
            related to your health the legal basis for processing is your
            explicit consent.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>2.4</b> We process information relating to provision of services
            by us to you ( “service data” ). The service data may include your
            contact details (such as your email address), bank account and
            transaction details as well as other information that you provide to
            us while filling up the relevant questionnaires (such may include
            sensitive personal data, related to your health, in case such data
            is necessary to provide the relevant service). The service data is
            processed to supply goods and provide services as well as keep
            proper records of those transactions. The legal basis for this
            processing is the performance of a contract between you and us
            and/or taking steps, at your request, to enter into such a contract
            and our legitimate interests, namely the proper administration of
            our website and business. In case of sensitive personal data,
            related to your health the legal basis for processing is your
            explicit consent.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>2.5</b> We may process information that you provide to us for the
            purpose of subscribing to our email messages and newsletters (
            “messaging data” ). The messaging data is processed to send you the
            relevant messages and newsletters. The legal basis for this
            processing is your consent. Also, if we have already sold goods or
            provided services for you via our website and/or apps, and you do
            not object, we may also process messaging data on the basis of our
            legitimate interest, namely seeking maintain and improve customer
            relations.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>2.6</b> We may process information relating to any communication
            that you send to us ( “correspondence data” ). The correspondence
            data may include the communication content and metadata associated
            with the communication. The correspondence data is processed for the
            purposes of communicating with you and record-keeping. The legal
            basis for this processing is our legitimate interests, namely the
            proper administration of our website and business, ensuring uniform
            and high quality consultation practice and for investigating
            disputes between you and our employees.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>2.7</b> We may process information on your use of our website
            and/or apps as well as on your device ( “device data” ). when you
            are browsing our website or use our apps. Device data may include IP
            address, geographical location, browser type and version, operating
            system, device type, screen resolution and (in case you agree to
            share such) your location data as well as information on the use of
            our website and apps (i.e. referral source, length of visit, page
            views and website navigation paths, as well as information about the
            timing, frequency and pattern of your service use). We obtain such
            data through the use of cookies and similar technologies. Device
            data is processed to enhance the apps and the website as well as to
            set default options. We also use such data to have a better
            understanding of how you use our website and services as well as for
            securing both the website and the apps. The legal basis for this
            processing is our legitimate interest, namely the proper management
            of our website, apps and business.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>2.8</b> We may process any of your personal data identified in
            this notice where necessary for the establishment, exercise or
            defence of legal claims, whether in court proceedings or in an
            administrative or out-of-court procedure. The legal basis for this
            processing is our legitimate interests, namely the protection and
            assertion of our legal rights, your legal rights and the legal
            rights of others.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>2.9</b> We may process any of your personal data identified in
            this notice where necessary for the purposes of obtaining or
            maintaining insurance coverage, managing risks, or obtaining
            professional advice. The legal basis for this processing is our
            legitimate interests, namely the proper protection of our business
            against risks.
          </Regular>
          <Regular>
            <b>2.1</b>0 In addition to the specific purposes for which we may
            process your personal data set out in this Section, we may also
            process any of your personal data where such processing is necessary
            for compliance with a legal obligation to which we are subject, or
            in order to protect your vital interests or the vital interests of
            another natural person.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">
            3. When we provide your data to others?
          </H3>
          <Regular margin="0 0 1.25rem">
            <b>3.1</b> We may disclose your personal data to any member of our
            group of companies (including our subsidiaries, our ultimate holding
            company and all its subsidiaries) insofar as reasonably necessary
            for the purposes set out in this notice. Such may include internal
            administration purposes as well as provision/sharing of IT, payment
            or marketing services or data centres in the group.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>3.2</b> We may disclose your personal data to our insurers and/or
            professional advisers insofar as reasonably necessary for the
            purposes of obtaining or maintaining insurance coverage, managing
            risks, obtaining professional advice, or the establishment, exercise
            or defence of legal claims, whether in court proceedings or in an
            administrative or out-of-court procedure.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>3.3</b> We may disclose your personal data to our anti-fraud,
            risks and compliance providers insofar as reasonably necessary for
            the purposes of protecting your personal data and fulfilling our
            legal obligations.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>3.4</b> We may disclose your personal data to our payment service
            providers. We will share service data with our payment services
            providers only to the extent necessary for the purposes of
            processing your payments, transferring funds and dealing with
            complaints and queries relating to such payments and transfers.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>3.5</b> We may disclose your personal data to other service
            providers insofar as it is reasonably necessary to provide specific
            services (including, providers of servers and maintenance thereof,
            email service providers, service providers used for data analysis or
            marketing, call centres, customer satisfaction surveys or market
            research). We take all the necessary measures to ensure that such
            subcontractors would implement proper organisational and technical
            measures to ensure security and privacy of your personal data.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>3.6</b> In addition to the specific disclosures of personal data
            set out in this Section, we may disclose your personal data where
            such disclosure is necessary for compliance with a legal obligation
            to which we are subject, or in order to protect your vital interests
            or the vital interests of another natural person.
          </Regular>
          <Regular>
            <b>3.7</b> Persons, indicated in this Section may be established
            outside the Republic of Lithuania, European Union and European
            Economic Area. In case we will transfer your personal data to such
            persons, we will take all the necessary and in the legal acts
            indicated measures to ensure that your privacy will remain properly
            secured, including where appropriate, signing standard contractual
            clauses for transfer of data. To find out more information regarding
            appropriate safeguards you may contact us via email:{' '}
            <a
              href="mailto:hi@pulsio.me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>hi@pulsio.me</TextColor>
            </a>
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">4. How long we store your data?</H3>
          <Regular margin="0 0 1.25rem">
            <b>4.1</b> Your personal data that we process for any purpose or
            purposes shall not be kept for longer than is necessary for that
            purpose or those purposes. In any case it shall be kept for no
            longer than:
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>1.</b> account data will be retained for no longer than 5 (five)
            years following your last update on the account;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>2.</b> service data will be retained for no longer than 5 (five)
            years following the end of provision of services;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>3.</b> messaging data will be retained for no longer than 2 (two)
            years following the provision of consent or, in case, the messaging
            data is being sent to the present clients in order to maintain and
            improve customer relations, for no longer than 2 (two) years
            following the end of provision of the respective services, unless
            you respectively withdraw your consent earlier or object to such
            processing;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>4.</b> correspondence data will be retained for no longer than 6
            (six) months following the end of such communication.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>4.2</b> In some cases, it is not possible for us to specify in
            advance the periods for which your personal data will be retained.
            I. e. device data will be retained for as much as will be necessary
            for the relevant processing purposes.
          </Regular>
          <Regular>
            <b>4.3</b> Notwithstanding the other provisions of this Section, we
            may retain your personal data where such retention is necessary for
            compliance with a legal obligation to which we are subject, or in
            order to protect your vital interests or the vital interests of
            another natural person.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">5. Marketing communication</H3>
          <Regular margin="0 0 1.25rem">
            <b>5.1</b> In case you consent, we will contact you via email or
            phone to inform on what we are up to. Also, if we already have
            provided services to you and you do not object, we will inform you
            about our other products that might interest you including other
            information related to such.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>5.2</b> You may opt-out of receiving marketing communications at
            any time. You may do so by choosing the relevant link in any of our
            marketing messages or contacting us via means provided in our
            website.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>5.2</b> Upon you having fulfilled any of the provided actions we
            will update your profile to ensure that you will not receive our
            marketing communication in the future.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>5.2</b> Please be informed that as our business activities
            consists of a network of closely related services, it may take a few
            days until all the systems are updated, thus you may continue to
            receive marketing communication while we are still processing your
            request.
          </Regular>
          <Regular>
            <b>5.2</b> In any case, the opt-out of the marketing communications
            will not stop you from receiving communication directly related to
            the provision of services.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">6. Your rights</H3>
          <Regular margin="0 0 1.25rem">
            <b>6.1</b> In this Section, we have summarised the rights that you
            have under data protection laws. Some of the rights are complex thus
            we only provide the main aspects of such rights. Accordingly, you
            should read the relevant laws (first and foremost the General Data
            Protection Regulation (EU) 2016/679) and guidance from the
            regulatory authorities for a full explanation of these rights.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.2</b> Your principal rights under data protection law are the
            following:
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>1.</b> the right to access data;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>2.</b> the right to rectification;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>3.</b> the right to erasure of your personal data;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>4.</b> the right to restrict processing of your personal data;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>5.</b> the right to object to processing of your personal data;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>6.</b> the right to complain to a supervisory authority; and
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>7.</b> the right to withdraw consent.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.3</b> The right to access data. You have the right to
            confirmation as to whether or not we process your personal data and,
            where we do, access to the personal data, together with certain
            additional information. That additional information includes details
            of the purposes of the processing, the categories of personal data
            concerned and the recipients of the personal data. Providing the
            rights and freedoms of others are not affected, we will supply to
            you a copy of your personal data. The first copy will be provided
            free of charge, but additional copies may be subject to a reasonable
            fee.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.4</b> The right to rectification. You have the right to have
            any inaccurate personal data about you rectified and, taking into
            account the purposes of the processing, to have any incomplete
            personal data about you completed.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.5</b> In some circumstances you have the right to the erasure
            of your personal data. Those circumstances include when: (i) the
            personal data are no longer necessary in relation to the purposes
            for which they were collected or otherwise processed; (ii) you
            withdraw consent to consent-based processing and there are no other
            legal basis to process data; (iii) you object to the processing
            under certain rules of applicable data protection laws; (iv) the
            processing is for direct marketing purposes; or (v) the personal
            data have been unlawfully processed. However, there are exclusions
            of the right to erasure. Such exclusions include when processing is
            necessary: (i) for exercising the right of freedom of expression and
            information; (ii) for compliance with our legal obligation; or (iii)
            for the establishment, exercise or defence of legal claims.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.6</b> In some circumstances you have the right to restrict the
            processing of your personal data Those circumstances are when: (i)
            you contest the accuracy of the personal data; (ii) processing is
            unlawful but you oppose erasure; (iii) we no longer need the
            personal data for the purposes of our processing, but you require
            personal data for the establishment, exercise or defence of legal
            claims; and (iv) you have objected to processing, pending the
            verification of that objection. Where processing has been restricted
            on this basis, we may continue to store your personal data, however
            we will only further process such data in any other way: (i) with
            your consent; (ii) for the establishment, exercise or defence of
            legal claims; (iii) for the protection of the rights of another
            person; or (iv) for reasons of important public interest.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.7</b> You have the right to object to our processing of your
            personal data on grounds relating to your particular situation, but
            only to the extent that the legal basis for the processing is that
            the processing is necessary for: the performance of a task carried
            out in the public interest or the purposes of the legitimate
            interests pursued by us or by a third party. If you make such an
            objection, we will cease to process the personal information unless
            we can demonstrate compelling legitimate grounds for the processing
            which override your interests, rights and freedoms, or the
            processing is for the establishment, exercise or defence of legal
            claims.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.8</b> You have the right to object to our processing of your
            personal data for direct marketing purposes (including profiling for
            direct marketing purposes). If you make such an objection, we will
            cease to process your personal data for this purpose.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.9</b> You have the right to object to our processing of your
            personal data for scientific or historical research purposes or
            statistical purposes on grounds relating to your particular
            situation, unless the processing is necessary for the performance of
            a task carried out for reasons of public interest.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.10</b> The right to data portability. To the extent that the
            legal basis for our processing of your personal data is:
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>1.</b>consent; or
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>2.</b>performance of a contract or steps to be taken at your
            request prior to entering into a contract, necessary to enter into
            such, you have the right to receive your personal data from us in a
            structured, commonly used and machine-readable format. However, this
            right does not apply where it would adversely affect the rights and
            freedoms of others.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.11</b> If you consider that our processing of your personal
            information infringes data protection laws, you have a legal right
            to lodge a complaint with a supervisory authority responsible for
            data protection. You may do so in the EU member state of your
            habitual residence, your place of work or the place of the alleged
            infringement. Our data processing is supervised by State Data
            Protection Inspectorate of the Republic of Lithuania, registered
            office at L. Sapiegos St. 17, LT-10312 Vilnius, www.ada.lt.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>6.12</b> To the extent that the legal basis for our processing of
            your personal information is consent, you have the right to withdraw
            that consent at any time. Withdrawal will not affect the lawfulness
            of processing before the withdrawal.
          </Regular>
          <Regular>
            <b>6.13</b> In addition to specific measure provided in this Section
            or the website you may also exercise any of the rights indicated
            herein by contacting us by email:{' '}
            <a
              href="mailto:hi@pulsio.me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>hi@pulsio.me</TextColor>
            </a>
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">7. About cookies</H3>
          <Regular margin="0 0 1.25rem">
            <b>7.1</b> Cookies are small textual files containing identifier
            that is sent by a web server to your web browser and is stored by
            the browser. The identifier is then sent back to the server each
            time the browser requests a page from the server.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>7.2</b> Cookies do not typically contain any information that
            personally identifies a user, but personal information that we store
            about you may be linked to the information stored in and obtained
            from cookies.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">8. Cookies that we use</H3>
          <Regular margin="0 0 1.25rem">
            In the website we use cookies of three main types, for the following
            purposes:
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>1.</b> Required cookies – used to ensure proper performance of
            the website, security of customers and their data, provision of
            high-quality services;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>2.</b> Functional cookies – used to enhance the website user
            experience, analyse the use of the system and in accordance to such
            improve the provision of services;
          </Regular>
          <Regular margin="0 0 0 2rem">
            <b>3.</b> Advertising cookies – used to observer user online
            behaviour and optimize marketing campaigns according to such
            information.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">
            9. Cookies used by our service providers
          </H3>
          <Regular margin="0 0 1.25rem">
            <b>9.1</b> Our service providers use cookies and those cookies may
            be stored on your computer when you visit our website.
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>1.</b> Google Analytics cookies to observe our website traffic.
            Cookies used for this purpose help us detect website errors as well
            as measure website bandwidth. You can view the privacy policy of
            Google Analytics{' '}
            <a
              href="https://google.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>here</TextColor>
            </a>
            ;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>2.</b> Youtube cookies to display in our website content uploaded
            in Youtube. Cookies used for this purpose help us maintain
            integrity, create informative and dynamic website. You can view the
            privacy policy of Youtube{' '}
            <a
              href="https://google.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>here</TextColor>
            </a>
            ;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>3.</b> Twitter cookies to display in our website content posted
            in Twitter. Cookies used for this purpose help us maintain
            integrity, create informative and dynamic website. You can view the
            privacy policy of Twitter{' '}
            <a
              href="https://google.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>here</TextColor>
            </a>
            ;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>4.</b> Google Maps cookies to, if the user permits, determine
            users location. Cookies used for this purpose help us adapt website
            settings in accordance to user’s location and improve user
            experience in our website. You can view the privacy policy of Google
            Maps{' '}
            <a
              href="https://google.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>here</TextColor>
            </a>
            ;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>5.</b> Doubleclick cookies to control the display of ads to our
            users. Cookies used for this purpose help us distinguish users that
            already use our services and reduce or stop displaying our ads to
            such users. You can view the privacy policy of Doubleclick{' '}
            <a
              href="https://google.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>here</TextColor>
            </a>
            ;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>6.</b> Facebook cookies to manage the display of ads to our
            users. Cookies used for this purpose help us distinguish users that
            already use our services and reduce or stop displaying our ads to
            such users. You can view the privacy policy of Facebook{' '}
            <a
              href="https://google.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>here</TextColor>
            </a>
            ;
          </Regular>
          <Regular margin="0 0 1.25rem 2rem">
            <b>7.</b> Google Tag Manager cookies to control advertising cookies.
            Cookies used for this purpose help us properly distribute ads to
            users. You can view the privacy policy of Google Tag Manager{' '}
            <a
              href="https://google.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>here</TextColor>
            </a>
            ;
          </Regular>
          <Regular margin="0 0 0 2rem">
            <b>8.</b> Hotjar cookies to observe how users use our website.
            Cookies used for this purpose help us observe the performance of the
            website and analyse how we can improve our website. You can view the
            privacy policy of Hotjar{' '}
            <a
              href="https://google.com"
              target="blank"
              rel="noopener noreferrer"
            >
              <TextColor color={primary}>here</TextColor>
            </a>
            ;
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">10. How can you manage cookies?</H3>
          <Regular margin="0 0 1.25rem">
            <b>10.1</b> Most browsers allow you to refuse to accept cookies and
            to delete cookies. The methods for doing so vary from browser to
            browser, and from version to version. You can however obtain
            up-to-date information about blocking and deleting cookies via
            information provided in the relevant browser website, for example
            Chrome; Firefox; Internet Explorer; Safari.
          </Regular>
          <Regular margin="0 0 1.25rem">
            <b>10.2</b> Blocking all cookies will have a negative impact upon
            the usability of many websites.
          </Regular>
          <Regular>
            <b>10.3</b> If you block cookies, you will not be able to use all
            the features on our website.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">11. Third party websites</H3>
          <Regular>
            In the website you may find links to and from partner sites,
            information sources and related party websites. Please take note
            that such third party website that you will visit by clicking on
            links have their own privacy policies and we take no responsibility
            regarding such privacy policies. We recommend familiarising with
            privacy policies of such websites before providing any personal data
            to such.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">12. Children personal data</H3>
          <Regular>
            Our website and services are targeted at persons over the age of
            18.11.2 If we have reason to believe that we hold personal data of a
            person under that age in our databases without having consent from
            the parent rights holder, we will delete that personal data.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">13. Updating your data</H3>
          <Regular>
            Please let us know if the personal information that we hold about
            you needs to be corrected or updated.
          </Regular>

          <H3 margin="4.375rem 0 1.875rem">14. Changes to the notice</H3>
          <Regular>
            Any changes to this notice will be published in the website and, in
            case of material changes, we may inform you about such via email or
            by other means which we will deem the most fitting in a particular
            case.
          </Regular>

          <Regular margin={isMobile ? '2rem 0 0' : '4.375rem 0 0'}>
            Last modified 2020.07.15
          </Regular>
        </Content>
      </Container>
    </>
  );
});

PrivacyPolicy.displayName = 'PrivacyPolicy';

export default PrivacyPolicy;

const Content = styled.div`
  max-width: 43.625rem;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 8.75rem;

  @media ${mobile} {
    padding-bottom: 3rem;
  }

  p {
    color: ${text70};
  }

  p b {
    color: ${black};
  }
`;
